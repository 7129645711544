body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100vh;
}

.selection {
  border: 1px dashed orange;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.button {
  width: 90vw;
  height: 10vh;
  margin: 30px 0;
  text-decoration: none;
  color: black;
  background-color: white;
  border: 3px solid black;
  cursor: pointer;
  text-align: center;
}

.button:hover {
  background-color: orangered;
}